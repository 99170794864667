<template>
  <a-layout class="chat-room">
    <a-layout-header class="header">
      <h1 style="display:flex; justify-content: center; align-items: center; height: 100%">Tj Connect!</h1>
    </a-layout-header>
    <a-layout-content>
      <div v-if="isConnected">
          <div class="top-bar">
            <a-alert :message="connectedMessage" class="success-message" type="success" show-icon />
            <a-button type="danger" @click="leaveRoom" class="leave-room-button">Leave Room</a-button>
          </div>
          <div v-if="currentRoom" class="room-content">
          <h2 class="room-name">Room: {{ currentRoom }}</h2>
          <a-list
            class="message-list"
            bordered
            :dataSource="messages"
            :renderItem="renderItem"
          />
          <p v-html="formattedContent(result)"></p>
          <p v-if="promptInfo" class="assistant-message">
            You might like this prompt: <a-tag color="blue" @click="handlePromptClick">{{ promptInfo.label }}</a-tag>
          </p>
           </div>
      </div>
      <div v-else>
        <a-spin tip="Connecting..." />
      </div>
      <div class="send-container">
            <a-input
            v-model="message"
            placeholder="Enter Message"
            @pressEnter="sendMessage"
            class="message-input"
          />
          <a-button type="primary" @click="sendMessage">
            Feedback
          </a-button>
        </div>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { io } from 'socket.io-client';
import marked from 'marked';

export default {
  props: ['roomId'],
  data() {
    return {
      socket: null,
      isConnected: false,
      username: '',
      currentRoom: '',
      message: '',
      messages: [],
      roomData: null,
      result: '',
      inProcess: false,
      promptInfo: null,
      aiConversationWantsToBeContinued: false,
    };
  },
  created() {
    this.connectSocket();
  },
  beforeDestroy() {
    this.disconnectSocket();
  },
  methods: {
    connectedMessage() {
      return `Connected as ${this.username}`;
    },
    formattedContent(content) {
      return marked(content);
    },
    handlePromptClick() {
      const customPromptMessage = `Hi eddie, use this given prompt to answer same question ${this.promptInfo.prompt}`;
      this.socket.emit('user_message', { message: customPromptMessage, roomId: this.currentRoom });
    },
    connectSocket() {
      const token = localStorage.getItem('userToken'); // Replace with your JWT token

      this.socket = io(process.env.VUE_APP_SOCKET_AI_SERVER_URI, {
        auth: {
          token,
        },
      });

      this.socket.on('connect', () => {
        this.isConnected = true;
        this.username = this.$store.state.auth.user.username || 'Anonymous';
        this.joinRoom(this.$route.params.roomId, this.$route.query.graphId); // Join the room from the URL
      });

      this.socket.on('get-room-data', (roomData) => {
        this.roomData = roomData;
        console.log('roomData available', roomData);
      });

      this.socket.on('query_ai_suggested_prompts', (promptInfo) => {
        this.promptInfo = promptInfo;
        console.log('promptInfo available', JSON.stringify(promptInfo));
      });

      this.socket.on('query_ai_response', (chunk) => {
        this.result += chunk;
        this.inProcess = true;
      });

      this.socket.on('query_ai_response_ended', () => {
        this.inProcess = false;
        this.messages.push({ user: 'assistant', text: this.result });
        setTimeout(() => {
          this.result = '';
          setTimeout(() => {
            if (this.aiConversationWantsToBeContinued) {
              this.socket.emit('start_ai_conversation', { roomId: this.currentRoom });
            }
          }, 10000);
        });
      });

      this.socket.on('user-joined', (data) => {
        this.messages.push({ id: Date.now(), user: 'System', text: `${data.user} joined the room` });
      });

      this.socket.on('user-message', (data) => {
        this.messages.push({ id: Date.now(), user: data.user, text: data.message });
      });

      this.socket.on('user-left', (data) => {
        this.messages.push({ id: Date.now(), user: 'System', text: `${data.user} left the room` });
      });

      this.socket.on('update_ai_status_client', (data) => {
        this.aiConversationWantsToBeContinued = data;
      });
    },
    disconnectSocket() {
      if (this.socket) {
        this.socket.disconnect();
        this.isConnected = false;
      }
    },
    joinRoom(roomId, graphId) {
      if (roomId) {
        this.socket.emit('join_ai_chat_room', { roomId, graphId });
        this.currentRoom = roomId;
      }
    },
    startAiConversation() {
      this.aiConversationWantsToBeContinued = true;
      this.socket.emit('update_ai_converstation_status', { roomId: this.currentRoom, statusAiRun: this.aiConversationWantsToBeContinued });
      this.socket.emit('start_ai_conversation', { roomId: this.currentRoom });
    },
    stopAiConversation() {
      this.aiConversationWantsToBeContinued = false;
      this.socket.emit('update_ai_converstation_status', { roomId: this.currentRoom, statusAiRun: this.aiConversationWantsToBeContinued });
    },
    leaveRoom() {
      if (this.currentRoom) {
        this.socket.emit('leave_ai_chat_room', this.currentRoom);
        this.currentRoom = '';
      }
    },
    sendMessage() {
      if (this.message && this.currentRoom) {
        this.socket.emit('user_message', { message: this.message, roomId: this.currentRoom });
        this.aiConversationWantsToBeContinued = true;
        this.socket.emit('update_ai_converstation_status', { roomId: this.currentRoom, statusAiRun: this.aiConversationWantsToBeContinued });
        this.message = '';
        this.promptInfo = null;
      }
    },
    renderItem(item) {
      return (
        <div key={item.id}>
          {item.user}: <div domPropsInnerHTML={this.formattedContent(item.text)}></div>
        </div>
      );
    },
  },
};
</script>

<style scoped>
.chat-room {
  min-height: 100vh;
}
.room-content {
  margin-top: 20px;
  padding-left: 120px;
  padding-right: 120px;
}
.message-input {
  width: 100%;
  border: none;
  background-color: white;
  margin-right: 10px;
  padding: 20px;
  border-radius: 15px;
}
.message-input:focus {
  border: none;
}
.message-list {
  margin-top: 20px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #ced4da;
  height: 300px;
  max-height: 500px;
  padding: 20px;
  overflow-y: auto;
}
.top-bar {
  display: inline-flex;
  gap: 20px;
}
 .room-name {
  color: #a5a5a5;
 }
.send-container{
  display: flex;
  padding-left: 120px;
  padding-right: 120px;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.header {
  color: #a5a5a5;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 120px;
  padding-right: 120px;
}

.user-message {
  background-color: #e6f7ff; /* Light blue */
   border-radius: 5px;
  width: 90%;
  padding: 8px;
  margin-bottom: 10px;
  float: right;
  text-align: left;
  align-items: center;
}
.assistant-message {
  background-color: rgb(250, 223, 237);
  border-radius: 5px;
  width: 90%;
  padding: 8px;
  margin-bottom: 5px;
  float: left;
  text-align: left;
  align-items: center;
}

</style>
